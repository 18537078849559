import { createContext, useState, useEffect, ReactNode } from "react";
import { GenericObject, Loading } from "components";
import { useFirebaseContext } from 'hooks';
import { CollectionReference, DocumentReference, collection, doc } from 'firebase/firestore';

export interface Refs {
    // refs
  addressesRef: CollectionReference;
  addressRef: (id: string) => DocumentReference;
  applicationsRef: CollectionReference;
  applicationRef: (id: string) => DocumentReference;
  billingAccountsRef: CollectionReference;
  billingAccountRef: (id: string) => DocumentReference;
  blastMessagesRef: CollectionReference;
  blocksRef: CollectionReference;
  blockedRef: (id: string) => DocumentReference;
  categoriesRef: CollectionReference;
  categoryRef: (id: string) => DocumentReference;
  categorySlugsRef: CollectionReference;
  categorySlugRef: (id: string) => DocumentReference;
  conversationsRef: CollectionReference;
  conversationRef: (id: string) => DocumentReference;
  conversationMessagesRef: (conversationId: string) => CollectionReference;
  conversationMessageRef: (conversationId: string, id: string) => DocumentReference;
  demoRef: (id: string) => DocumentReference;
  eventsRef: CollectionReference;
  eventRef: (id: string) => DocumentReference;
  eventAttendeeRef: (eventId: string, id: string) => DocumentReference;
  eventBidsRef: (eventId: string) => CollectionReference;
  eventBidRef: (eventId: string, id: string) => DocumentReference;
  eventChatsRef: (id: string) => CollectionReference;
  eventChatRef: (eventId: string, id: string) => DocumentReference;
  eventIndexRef: (id: string) => DocumentReference;
  eventPrivateRef: (eventId: string) => CollectionReference;
  eventPrivateStatsRef: (eventId: string) => DocumentReference;
  chatReactionsRef: (eventId: string, chatId: string) => CollectionReference;
  chatReactionRef: (eventId: string, chatId: string, uid: string) => DocumentReference;
  eventSlugsRef: CollectionReference;
  eventSlugRef: (id: string) => DocumentReference;
  eventStreamsRef: CollectionReference;
  eventStreamRef: (id: string) => DocumentReference;
  invitesRef: CollectionReference;
  invitationRef: (id: string) => DocumentReference;
  invoicesRef: CollectionReference;
  invoiceRef: (id: string) => DocumentReference;
  notificationsRef: CollectionReference;
  notificationRef: (id: string) => DocumentReference;
  messagesRef: CollectionReference;
  messageRef: (id: string) => DocumentReference;
  messageThreadsRef: (id: string) => CollectionReference;
  messageThreadRef: (messageId: string, id: string) => DocumentReference;
  /* FACEBOOK */
  facebookPagesRef: CollectionReference;
  facebookPageRef: (id: string) => DocumentReference;
  facebookUsersRef: CollectionReference;
  facebookUserRef: (providerUid: string) => DocumentReference;
  facebookPSIDsRef: CollectionReference;
  facebookPSIDRef: (id: string) => DocumentReference;
  facebookPostsRef: (orgId: string) => CollectionReference;
  facebookPostRef: (orgId: string, postId: string) => DocumentReference;
  facebookPageRepliesRef: (orgId: string, pageId: string) => CollectionReference;
  facebookPageReplyRef: (orgId: string, pageId: string, id: string) => DocumentReference;
  liveCommentsRef: (eventId: string) => CollectionReference;
  liveCommentRef: (eventId: string, commentId: string) => DocumentReference;

  /* INSTAGRAM */
  instagramAccountsRef: (orgId: string) => CollectionReference;
  instagramAccountRef:  (orgId: string, id: string) => DocumentReference;
  instagramAccountRepliesRef: (orgId: string, accountId: string) => CollectionReference;
  instagramAccountReplyRef: (orgId: string, accountId: string, id: string) => DocumentReference;
  instagramAccountPostsRef: (orgId: string, accountId: string) => CollectionReference;
  instagramAccountPostRef: (orgId: string, accountId: string, id: string) => CollectionReference;
  instagramAccountContactsRef: (orgId: string, accountId: string) => CollectionReference;

  /* TIKTOK */
  tiktokAccountsRef: (orgId: string) => CollectionReference;
  tiktokAccountRef:  (orgId: string, id: string) => DocumentReference;
  
  videoSessionsRef: (eventId: string) => CollectionReference;
  videoSessionRef: (eventId: string, id: string) => DocumentReference;
  eventAttendeesRef: (eventId: string) => CollectionReference;
  eventFacebookChatUsersRef: (eventId: string) => CollectionReference;
  eventsIndexRef: CollectionReference;
  productsRef: CollectionReference;
  productRef: (id: string) => DocumentReference;
  purchasesRef: CollectionReference;
  purchaseRef: (id: string) => DocumentReference;
  publicEventsIndexRef: CollectionReference;
  publicEventIndexRef: (id: string) => DocumentReference;
  merchantAccountsRef: CollectionReference;
  merchantAccountRef: (id: string) => DocumentReference;
  rootCategoryRef: DocumentReference;
  organizationsRef: CollectionReference;
  organizationRef: (id: string) => DocumentReference;
  organizationCarriersRef: (id: string) => CollectionReference;
  organizationCarrierRef: (orgId: string, id: string) => DocumentReference;
  organizationCustomersRef: (id: string) => CollectionReference;
  organizationCustomerRef: (orgId: string, id: string) => DocumentReference;
  organizationGroupsRef: (id: string) => CollectionReference;
  organizationGroupRef: (orgId: string, id: string) => DocumentReference;
  organizationInvoicesRef: (id: string) => CollectionReference;
  organizationInvoiceRef: (orgId: string, id: string) => DocumentReference;
  organizationMembersRef: (id: string) => CollectionReference;
  organizationMemberRef: (orgId: string, id: string) => DocumentReference;
  organizationPrivateRef: (id: string) => CollectionReference;
  organizationPrivateDataRef: (id: string) => DocumentReference;
  organizationPrivateStatsRef: (id: string) => DocumentReference;
  organizationsSettingsRef: CollectionReference;
  organizationSettingsRef: (id: string) => DocumentReference;
  organizationFacebookPagesRef: (orgId: string) => CollectionReference;
  organizationFacebookPageRef: (orgId: string, id: string) => DocumentReference;
  facebookCommentsRef: (orgId: string, postId: string) => CollectionReference;
  facebookCommentRef: (orgId: string, postId: string, id: string) => DocumentReference;
  facebookCommentRepliesRef: (orgId: string, postId: string, commentId: string) => CollectionReference;
  facebookCommentReplyRef: (orgId: string, postId: string, commentId: string, id: string) => DocumentReference;
  paymentsRef: CollectionReference;
  paymentRef: (id: string) => DocumentReference;
  refundsRef: CollectionReference;
  storesRef: CollectionReference;
  storeInventoryRef: (orgId: string) => CollectionReference;
  storeInventoryItemRef: (orgId: string, itemId: string) => DocumentReference;
  inventoryRef: (orgId: string) => CollectionReference;
  inventoryItemRef: (orgId: string, itemId: string) => DocumentReference;
  storeRef: (id: string) => DocumentReference;
  subscriptionsRef: CollectionReference;
  subscriptionRef: (id: string) => DocumentReference;
  ticketRef: (id: string) => DocumentReference;
  usernameRef: (id: string) => DocumentReference;
  userProfilesRef: CollectionReference;
  userProfileRef: (id: string) => DocumentReference;
  userPrivateFacebookRef: (id: string) => DocumentReference;
  shopifyRef: CollectionReference;
  shopifyStoreRef: (id: string) => DocumentReference;
  shopifySubscriptionsRef: CollectionReference;
  publicOrganizationIndexRef: (id: string) => DocumentReference;
}

export const RefsContext = createContext<Partial<Refs>>({} as GenericObject);
export const RefsConsumer = RefsContext.Consumer;

export const RefsProvider = ({ children }:{ children: ReactNode }) => {

  const [refs, setRefs] = useState<GenericObject>();

  const {db} = useFirebaseContext();

  useEffect(() => {
    if (db) {
        const addressesRef = collection(db, 'addresses');
        const addressRef = (id: string) => doc(addressesRef, id);
        const applicationsRef = collection(db, 'applications');
        const applicationRef = (id: string) => doc(applicationsRef, id);
        const billingAccountsRef = collection(db, 'billing_accounts');
        const billingAccountRef = (id: string) => doc(billingAccountsRef, id);
        const blastMessagesRef = collection(db, 'blast_messages');
        const blocksRef = collection(db, 'blocked');
        const blockedRef = (id: string) => doc(blocksRef, id);
        const categoriesRef = collection(db, 'categories');
        const categoryRef = (id: string) => doc(categoriesRef, id);
        const categorySlugsRef = collection(db, 'category_slugs');
        const categorySlugRef = (id: string) => doc(categorySlugsRef, id);
        const conversationsRef = collection(db, 'conversations');
        const conversationRef = (id: string) => doc(conversationsRef, id);
        const conversationMessagesRef = (conversationId: string) => 
            collection(conversationRef(conversationId), 'messages');
        const conversationMessageRef = (conversationId: string, id: string) => 
            doc(conversationMessagesRef(conversationId), id);
        const demoRef = (id: string) => doc(db, `demo/${id}`);
        const eventsRef = collection(db, 'events');
        const eventRef = (id: string) => doc(eventsRef, id);
        const eventAttendeesRef = (id: string) => collection(eventRef(id), 'attendees');
        const eventAttendeeRef = (eventId: string, id: string) => doc(eventAttendeesRef(eventId), id);
        const eventBidsRef = (eventId: string) => collection(eventRef(eventId), 'bids');
        const eventBidRef = (eventId: string, id: string) => doc(eventBidsRef(eventId), id);
        const eventChatsRef = (id: string) => collection(eventRef(id), 'chats');
        const eventChatRef = (eventId: string, id: string) => doc(eventChatsRef(eventId), id);
        const eventPrivateRef = (eventId: string) =>
            collection(eventRef(eventId), 'private');
        const eventPrivateStatsRef = (eventId: string) => doc(eventPrivateRef(eventId), 'stats');
        const chatReactionsRef = (eventId: string, chatId: string) => collection(eventChatRef(eventId, chatId), 'reactions');
        const chatReactionRef = (eventId: string, chatId: string, uid: string) => doc(chatReactionsRef(eventId, chatId), uid);
        const eventSlugsRef = collection(db, 'slugs');
        const eventSlugRef = (id: string) => doc(eventSlugsRef, id);
        const invitesRef = collection(db, 'invites');
        const invitationRef = (id: string) => doc(invitesRef, id);
        const notificationsRef = collection(db, 'notifications');
        const notificationRef = (id: string) => doc(notificationsRef, id);
        const messagesRef = collection(db, 'messages');
        const messageRef = (id: string) => doc(messagesRef, id);
        const messageThreadsRef = (id: string) => collection(messageRef(id), 'thread');
        const messageThreadRef = (messageId: string, id: string) => doc(messageThreadsRef(messageId), id);
        const videoSessionsRef = (eventId: string) =>
            collection(eventRef(eventId), 'video_sessions');
        const videoSessionRef = (eventId: string, id: string) =>
            doc(videoSessionsRef(eventId), id);
        const eventFacebookChatUsersRef = (eventId: string) =>
            collection(eventRef(eventId), 'facebook_chat_users');
        const eventStreamsRef = collection(db, 'event_streams');
        const eventStreamRef = (id: string) => doc(eventStreamsRef, id);
        const eventsIndexRef = collection(db, 'event_index');
        const eventIndexRef = (eventId: string) =>
            doc(eventsIndexRef, eventId);
        const invoicesRef = collection(db, 'invoices');
        const invoiceRef = (id: string) => doc(invoicesRef, id);
        /* FACEBOOK */
        const facebookPagesRef = collection(db, 'facebook_pages');
        const facebookPageRef = (id: string) => doc(facebookPagesRef, id);
        const facebookUsersRef = collection(db, 'facebook_users');
        const facebookUserRef = (providerUid: string) => doc(facebookUsersRef, providerUid);
        const facebookPSIDsRef = collection(db, 'facebook_psid');
        const facebookPSIDRef = (psid: string) => doc(facebookPSIDsRef, psid);
        const liveCommentsRef = (eventId: string) => collection(eventRef(eventId), 'live_comments');
        const liveCommentRef = (eventId: string, commentId: string) => doc(liveCommentsRef(eventId), commentId);

        /* Instagram */
        const instagramAccountsRef = (orgId: string) => collection(organizationRef(orgId), 'instagram');
        const instagramAccountRef = (orgId: string, id: string) => doc(instagramAccountsRef(orgId), id);
        const instagramAccountRepliesRef = (orgId: string, accountId: string) => collection(instagramAccountRef(orgId, accountId), 'replies');
        const instagramAccountReplyRef = (orgId: string, accountId: string, id: string) => doc(instagramAccountRepliesRef(orgId, accountId), id);
        const instagramAccountPostsRef = (orgId: string, accountId: string) => collection(instagramAccountRef(orgId, accountId), 'media');
        const instagramAccountPostRef = (orgId: string, accountId: string, id: string) => doc(instagramAccountPostsRef(orgId, accountId), id);
        const instagramAccountContactsRef = (orgId: string, accountId: string) => collection(instagramAccountRef(orgId, accountId), 'contacts');

        /* Tiktok */
        const tiktokAccountsRef = (orgId: string) => collection(organizationRef(orgId), 'tiktok');
        const tiktokAccountRef = (orgId: string, id: string) => doc(tiktokAccountsRef(orgId), id);

        const merchantAccountsRef = collection(db, 'merchant_accounts');
        const merchantAccountRef = (id: string) => doc(merchantAccountsRef, id);
        /* organizations */
        const organizationsRef = collection(db, 'organizations');
        const organizationRef = (id: string) => doc(organizationsRef, id);
        const organizationCarriersRef = (id: string) => collection(organizationRef(id), 'carriers');
        const organizationCarrierRef = (orgId: string, id: string) => doc(organizationCarriersRef(orgId), id);
        const organizationCustomersRef = (id: string) => collection(organizationRef(id), 'customers');
        const organizationCustomerRef = (orgId: string, id: string) => doc(organizationCustomersRef(orgId), id);
        const organizationGroupsRef = (id: string) => collection(organizationRef(id), 'groups');
        const organizationGroupRef = (orgId: string, id: string) => doc(organizationGroupsRef(orgId), id);
        const organizationInvoicesRef = (id: string) => collection(organizationRef(id), 'invoices');
        const organizationInvoiceRef = (orgId: string, id: string) => doc(organizationInvoicesRef(orgId), id);
        const organizationMembersRef = (id: string) => collection(organizationRef(id), 'members');
        const organizationMemberRef = (orgId: string, id: string) => doc(organizationMembersRef(orgId), id);
        const organizationPrivateRef = (id: string) => collection(organizationRef(id), 'private');
        const organizationPrivateDataRef = (id: string) => doc(organizationPrivateRef(id), 'data');
        const organizationPrivateStatsRef = (id: string) => doc(organizationPrivateRef(id), 'stats');
        const organizationsSettingsRef = collection(db, 'organization_settings');
        const organizationSettingsRef = (id: string) => doc(organizationsSettingsRef, id);
        /* FACEBOOK FOR ORGANIZATION */
        const organizationFacebookPagesRef = (orgId: string) => collection(organizationRef(orgId), 'facebook_pages');
        const organizationFacebookPageRef = (orgId: string, id: string) => doc(organizationFacebookPagesRef(orgId), id);
        const facebookPageRepliesRef = (orgId: string, pageId: string) => collection(organizationFacebookPageRef(orgId, pageId), 'replies');
        const facebookPageReplyRef = (orgId: string, pageId: string, id: string) => doc(facebookPageRepliesRef(orgId, pageId), id);
        const facebookPostsRef = (orgId: string) => collection(organizationRef(orgId), 'facebook_posts');
        const facebookPostRef = (orgId: string, postId: string) => doc(facebookPostsRef(orgId), postId);
        const facebookCommentsRef = (orgId: string, postId: string) => collection(facebookPostRef(orgId, postId), 'comments');
        const facebookCommentRef = (orgId: string, postId: string, id: string) => doc(facebookCommentsRef(orgId, postId), id);
        const facebookCommentRepliesRef = (orgId: string, postId: string, commentId: string) => collection(facebookCommentRef(orgId, postId, commentId), 'replies');
        const facebookCommentReplyRef = (orgId: string, postId: string, commentId: string, id: string) => doc(facebookCommentRepliesRef(orgId, postId, commentId), id);
        const paymentsRef = collection(db, 'payments');
        const paymentRef = (id: string) => doc(paymentsRef, id);
        const productsRef = collection(db, 'products');
        const productRef = (id: string) => doc(productsRef, id);
        const purchasesRef = collection(db, 'purchases');
        const purchaseRef = (id: string) => doc(purchasesRef, id);
        const publicEventsIndexRef = collection(db, 'public_event_index');
        const publicEventIndexRef = (id: string) => doc(publicEventsIndexRef, id);
        const subscriptionsRef = collection(db, 'subscriptions');
        const subscriptionRef = (id: string) => doc(subscriptionsRef, id);
        const refundsRef = collection(db, 'refunds');
        const rootCategoryRef = doc(categoriesRef, 'root');
        const storesRef = collection(db, 'stores');
        const storeRef = (id: string) => doc(storesRef, id);
        const storeInventoryRef = (orgId: string) => collection(storeRef(orgId), 'inventory');
        const storeInventoryItemRef = (orgId: string, itemId: string) => doc(storeInventoryRef(orgId), itemId);
        const inventoryRef = (orgId: string) => collection(organizationRef(orgId), 'inventory');
        const inventoryItemRef = (orgId: string, id: string) => doc(inventoryRef(orgId), id);
        const ticketRef = (id: string) => doc(db, `tickets/${id}`);
        const usernameRef = (id: string) => doc(db, `usernames/${id}`);
        const userProfilesRef = collection(db, 'users');
        const userProfileRef = (id: string) => doc(userProfilesRef, id);
        const userPrivateRef = (id: string) => collection(userProfileRef(id), 'private');
        const userPrivateFacebookRef = (id: string) => doc(userPrivateRef(id), 'facebook');
        const shopifyRef = collection(db, 'shopify');
        const shopifyStoreRef = (id: string) => doc(shopifyRef, id);
        const shopifySubscriptionsRef = collection(db, 'shopify_subscriptions');
        const publicOrganizationIndexRef = (id: string) => doc(collection(db, 'public_organization_index'), id);

        setRefs({
            addressesRef,
            addressRef,
            applicationsRef,
            applicationRef,
            billingAccountsRef,
            billingAccountRef,
            blastMessagesRef,
            blocksRef,
            blockedRef,
            categoriesRef,
            categoryRef,
            categorySlugsRef,
            categorySlugRef,
            conversationsRef,
            conversationRef,
            conversationMessagesRef,
            conversationMessageRef,
            demoRef,
            eventsRef,
            eventRef,
            eventAttendeesRef,
            eventAttendeeRef,
            eventBidsRef,
            eventBidRef,
            eventChatsRef,
            eventChatRef,
            eventPrivateRef,
            eventPrivateStatsRef,
            chatReactionsRef,
            chatReactionRef,
            eventSlugsRef,
            eventSlugRef,
            invitesRef,
            invitationRef,
            notificationsRef,
            notificationRef,
            messagesRef,
            messageRef,
            messageThreadsRef,
            messageThreadRef,
            videoSessionsRef,
            videoSessionRef,
            eventFacebookChatUsersRef,
            eventStreamsRef,
            eventStreamRef,
            eventsIndexRef,
            eventIndexRef,
            invoicesRef,
            invoiceRef,
            facebookPagesRef,
            facebookPageRef,
            facebookUsersRef,
            facebookUserRef,
            facebookPSIDsRef,
            facebookPSIDRef,
            merchantAccountsRef,
            merchantAccountRef,
            organizationsRef,
            organizationRef,
            organizationCarriersRef,
            organizationCarrierRef,
            organizationCustomersRef,
            organizationCustomerRef,
            organizationGroupsRef,
            organizationGroupRef,
            organizationInvoicesRef,
            organizationInvoiceRef,
            organizationMembersRef,
            organizationMemberRef,
            organizationPrivateRef,
            organizationPrivateDataRef,
            organizationPrivateStatsRef,
            organizationsSettingsRef,
            organizationSettingsRef,
            organizationFacebookPagesRef,
            organizationFacebookPageRef,
            facebookPostsRef,
            facebookPostRef,
            facebookCommentsRef,
            facebookCommentRef,
            facebookCommentRepliesRef,
            facebookCommentReplyRef,
            liveCommentsRef,
            liveCommentRef,
            paymentsRef,
            paymentRef,
            productsRef,
            productRef,
            purchasesRef,
            purchaseRef,
            publicEventsIndexRef,
            publicEventIndexRef,
            subscriptionsRef,
            subscriptionRef,
            refundsRef,
            rootCategoryRef,
            storesRef,
            storeRef,
            storeInventoryRef,
            storeInventoryItemRef,
            inventoryRef,
            inventoryItemRef,
            ticketRef,
            usernameRef,
            userProfilesRef,
            userProfileRef,
            userPrivateFacebookRef,
            shopifyRef,
            shopifyStoreRef,
            shopifySubscriptionsRef,
            publicOrganizationIndexRef,
            facebookPageRepliesRef,
            facebookPageReplyRef,
            instagramAccountsRef,
            instagramAccountRef,
            instagramAccountRepliesRef,
            instagramAccountReplyRef,
            instagramAccountPostsRef,
            instagramAccountPostRef,
            instagramAccountContactsRef,
            tiktokAccountsRef,
            tiktokAccountRef,
        });
    }
  },[db]);

  if (!refs || !db) return <Loading/>;

  return (
    <RefsContext.Provider value={{...refs}}>
      {children}
    </RefsContext.Provider>
  );
  
};
