import ReactDOM from 'react-dom/client';
import App from './App';
import '@fontsource/roboto';

const root = ReactDOM.createRoot(
  document.getElementById('kwixl-app') as HTMLElement
);

root.render(
    <App />
);
